import { cn } from "@/lib/utils";
import React from "react";

type SectionHeaderProps = {
  className?: string;
  children: React.ReactNode;
  subtext?: React.ReactNode;
  action?: React.ReactNode;
};

export const SectionHeader = ({
  className,
  children,
  subtext,
  action,
}: SectionHeaderProps) => (
  <div className="flex flex-row justify-between items-center mb-8">
    <div className={cn("flex flex-col", className)}>
      <h2 className={cn("text-title-3 font-bold", subtext && "mb-4")}>
        {children}
      </h2>
      {subtext && <p className="text-headline text-subtitle">{subtext}</p>}
    </div>
    {action && <div>{action}</div>}
  </div>
);
