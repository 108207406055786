import { Tab, Tabs } from "@/components/module/Tabs";
import { useApp } from "@/context/app-provider";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, Outlet } from "react-router-dom";

export const SettingsLayout = () => {
  const { setTopbarName, setTopbarBottomBorder } = useApp();

  useEffect(() => {
    setTopbarName("Settings");
    setTopbarBottomBorder(false);

    return () => {
      setTopbarBottomBorder(true);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Konko AI</title>
        <link rel="canonical" href="https://platform.konko.ai/settings" />
      </Helmet>
      <div className="flex h-full flex-1 flex-col">
        <Tabs className="flex-row flex-wrap">
          <NavLink to="/settings/profile">
            {({ isActive }) => <Tab isActive={isActive}>Profile</Tab>}
          </NavLink>
          <NavLink to="/settings/api-keys">
            {({ isActive }) => <Tab isActive={isActive}>API keys</Tab>}
          </NavLink>
          <NavLink to="/settings/organization">
            {({ isActive }) => <Tab isActive={isActive}>Organization</Tab>}
          </NavLink>
          <NavLink to="/settings/team">
            {({ isActive }) => <Tab isActive={isActive}>Team</Tab>}
          </NavLink>
          <NavLink to="/settings/whatsapp">
            {({ isActive }) => <Tab isActive={isActive}>WhatsApp</Tab>}
          </NavLink>
          <NavLink to="/settings/chatwoot">
            {({ isActive }) => <Tab isActive={isActive}>Chatwoot</Tab>}
          </NavLink>
        </Tabs>
        <div className="max-w-7xl flex-1 p-8">
          <Outlet />
        </div>
      </div>
    </>
  );
};
