import axios, { isAxiosError as _isAxiosError } from "axios";
import { toast } from "react-toastify";

const baseURL = import.meta.env.VITE_PUBLIC_API_BASE_URL;

export const httpClient = axios.create({
  baseURL,
});

const agentBaseUrl = import.meta.env.VITE_PUBLIC_AGENT_API_BASE_URL;

export const agentHttpClient = axios.create({
  baseURL: agentBaseUrl,
});

export const isAxiosError = _isAxiosError;

export const handleAxiosError = (error: unknown, login: () => void): void => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      login();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const message =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      error.response?.data.detail &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      typeof error.response?.data.detail === "string"
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          error.response?.data.detail
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          error.response?.data.detail[0].msg
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            error.response?.data.detail[0].msg
          : "Something went wrong";
    console.log(message);
    toast.error(message as string);
  } else {
    // This is not an Axios error, so re-throw it
    throw error;
  }
};
