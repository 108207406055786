import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const isValidJsonString = (str: string): boolean => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const obj: Record<string, unknown> = JSON.parse(str);
    return obj && typeof obj === "object";
  } catch (e) {
    return false;
  }
};

export const getLocalStorage = <T>(storageKey: string): T | null => {
  const value = localStorage.getItem(storageKey);
  if (value !== null) {
    try {
      return JSON.parse(value) as T;
    } catch {
      return null;
    }
  }
  return null;
};

export const setLocalStorage = <T>(key: string, value: T): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const truncateString = (str: string): string =>
  str.length <= 7 ? str : `${str.slice(0, 3)}...${str.slice(-4)}`;

export const copyToClipboard = (text: string): Promise<void> => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    return new Promise((resolve, reject) => {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        resolve();
      } catch (err) {
        reject(err);
      } finally {
        document.body.removeChild(textarea);
      }
    });
  }
};

export const replaceInsertTags = (
  str: string,
  replacements: (string | number)[]
) => {
  let i = 0;
  return str.replace(/{{INSERT}}/g, () => "" + replacements[i++]);
};

export const getMinMax = (
  val: number,
  min?: number,
  max?: number,
  exclusiveMin?: number,
  exclusiveMax?: number
): number => {
  if (min !== undefined && val <= min) {
    return min;
  }
  if (max !== undefined && val >= max) {
    return max;
  }
  if (exclusiveMin !== undefined && val <= exclusiveMin) {
    return exclusiveMin + 1;
  }
  if (exclusiveMax !== undefined && val >= exclusiveMax) {
    return exclusiveMax - 1;
  }
  return val;
};
