import { Progress } from "@/components/base/Progress";
import { useEffect, useState } from "react";

export const RedirectToSignIn = () => {
  const [progress, setProgress] = useState(13);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prev + 10;
      });
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex h-screen w-screen flex-grow flex-col items-center justify-center gap-5 align-middle">
      <span className="text-page-title text-tx-secondary animate-pulse">
        Redirecting to sign in...
      </span>
      <img
        src="/logo/app-icon-light.svg"
        alt="Loading..."
        height="70px"
        width="70px"
      />
      <Progress value={progress} className="w-[25%]" />
    </div>
  );
};
