import { Anchor } from "@/components/base/Anchor";
import { Avatar } from "@/components/base/Avatar";
import { Button } from "@/components/base/Button";
import { Divider } from "@/components/base/Divider";
import { Popover } from "@/components/base/Popover";
import { useApp } from "@/context/app-provider";
import { useOrg } from "@/context/org-provider";
import { useUser } from "@/context/user-provider";
import { cn } from "@/lib/utils";
import { Moon, Sun } from "lucide-react";
import { Outlet } from "react-router-dom";
import { Topbar } from "../Topbar";

export const Layout = () => {
  const { user, auth0Loading: userLoading, logout } = useUser();
  const {
    topbarName,
    theme,
    setTheme,
    topbarBottomBorder,
    sidebarExpanded,
  } = useApp();
  const { selectedOrganization, setSelectedOrganization, organizations } =
    useOrg();

  return (
    <div className="flex flex-1 flex-col">
      <Topbar borderBottom={topbarBottomBorder}>
        <div className="flex flex-1 items-center justify-between">
          <div
            className="flex h-fit w-fit items-center justify-between gap-4 pl-1"
          >
            <div
              className="block h-8 w-8 overflow-hidden transition-all delay-75"
            >
              <img
                src="/logo/app-icon-light.svg"
                alt="Konko AI logo"
                className="h-full"
              />
            </div>
            <span className="text-page-title">{topbarName}</span>
          </div>
          <div className="flex items-center gap-10">
            <div>
              <Button
                variant="outline"
                size="icon"
                onClick={() => {
                  setTheme(theme === "light" ? "dark" : "light");
                }}
                className="text-tx"
              >
                {theme === "light" ? <Moon /> : <Sun />}
              </Button>
            </div>

            <Popover
              popoverTriggerProps={{
                asChild: false,
                className: cn(
                  "align-baseline flex flex-row items-center w-full ml-2",
                  userLoading ? "animate-pulse" : "animate-none"
                ),
              }}
              popoverContentProps={{
                align: "end",
                sideOffset: 10,
              }}
              content={
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <h4 className="text-subtext-bold">
                      {userLoading ? "Loading..." : user?.name}
                    </h4>
                    <p className="text-subtext">
                      {userLoading ? "Loading..." : user?.email}
                    </p>
                  </div>
                  <Divider size={1} />
                  <div className="flex flex-col gap-2">
                    <h4 className="text-subtext-bold">Organizations</h4>
                    <div className="flex flex-col gap-2">
                      {organizations?.map((org) => (
                        <Button
                          variant="ghost"
                          size="full"
                          className="justify-start truncate p-2"
                          key={org.id}
                          onClick={() => setSelectedOrganization(org)}
                          selected={selectedOrganization?.id === org.id}
                        >
                          {org.name ?? org.id}
                        </Button>
                      ))}
                    </div>
                  </div>
                  <Divider size={1} />
                  <div className="flex flex-col gap-2">
                    <Anchor
                      href="https://www.konko.ai/terms"
                      target="_blank"
                      className="visited:text- justify-start p-2"
                    >
                      Terms and conditions
                    </Anchor>
                    <Button
                      variant="transparent"
                      size="full"
                      className="justify-start p-2"
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </div>
                </div>
              }
            >
              <>
                {user?.picture ? (
                  <Avatar
                    src={user?.picture}
                    alt={`${user?.name}-pfp`}
                    className="h-10 w-10"
                  />
                ) : (
                  <Avatar className="h-10 w-10">
                    {user?.name ? user?.name[0] : "?"}
                  </Avatar>
                )}
                <h4
                  className={cn(
                    "text-tx-foreground font-semibold overflow-hidden whitespace-nowrap break-keep leading-4 transition-all",
                    sidebarExpanded
                      ? "ml-3 opacity-100 transition-opacity duration-100"
                      : "w-0 opacity-0"
                  )}
                >
                  {userLoading ? "Loading..." : user?.name}
                </h4>
              </>
            </Popover>
          </div>
        </div>
      </Topbar>
      <main className="min-h-[calc(100vh_-_79px)] flex-1">{<Outlet />}</main>
    </div>
  );
};
