import { httpClient, isAxiosError } from "@/lib/http";
import { Organization } from "@/types/organizations";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useUser } from "./user-provider";

type OrgProviderProps = {
  children: React.ReactNode;
};

type OrgProviderState = {
  organizations: Organization[] | null;
  selectedOrganization: Organization | null;
  setSelectedOrganization: (organization: Organization) => void;
  refresh: () => Promise<void>;
  loading: boolean;
};

const initialState: OrgProviderState = {
  organizations: null,
  selectedOrganization: null,
  setSelectedOrganization: () => null,
  refresh: () => Promise.resolve(),
  loading: true,
};

const OrgProviderContext = createContext<OrgProviderState>(initialState);

export const OrgProvider = ({ children }: OrgProviderProps) => {
  const { login, user, getAccessToken } = useUser();
  const [organizations, setOrganizations] = useState<
    OrgProviderState["organizations"]
  >(initialState.organizations);
  const [selectedOrganization, setSelectedOrganization] = useState<
    OrgProviderState["selectedOrganization"]
  >(initialState.selectedOrganization);
  const [loading, setLoading] = useState<OrgProviderState["loading"]>(
    initialState.loading
  );

  const getOrgDetails = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken();
      const organizationsResponse = await httpClient.get<{
        organizations: Organization[];
      }>("/v1/organizations", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOrganizations(organizationsResponse.data.organizations);
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          login();
        }
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const refresh = async () => {
    return await getOrgDetails();
  };

  const selectDefaultOrganization = () => {
    if (selectedOrganization) {
      return;
    }
    const defaultOrg = user?.organizations.find((o) => o.is_default);
    if (!defaultOrg) {
      return;
    }

    setSelectedOrganization({
      id: defaultOrg?.id ?? "",
      name: defaultOrg?.name ?? "",
    });
  };

  useEffect(() => {
    getOrgDetails().catch(console.error);
  }, []);

  useEffect(() => {
    selectDefaultOrganization();
  }, [user]);

  const value = useMemo(
    () => ({
      organizations,
      selectedOrganization,
      setSelectedOrganization,
      refresh,
      loading,
    }),
    [organizations, selectedOrganization, loading]
  );

  return (
    <OrgProviderContext.Provider value={value}>
      {children}
    </OrgProviderContext.Provider>
  );
};

export const useOrg = () => {
  const context = useContext(OrgProviderContext);

  if (context === undefined)
    throw new Error("useOrg must be used within a OrgProvider");

  return context;
};
