import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

type Props = {
  children: React.ReactNode;
};

export const ReactQueryProvider = ({ children }: Props) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retry: true,
      },
    },
    queryCache: new QueryCache({
      onError: (error: unknown) => {
        console.error(JSON.stringify(error));
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: unknown) => {
        console.error(JSON.stringify(error));
      },
    }),
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
