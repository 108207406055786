import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return import.meta.env.PROD ? (
    <SentryErrorBoundary
      // eslint-disable-next-line @typescript-eslint/unbound-method
      fallback={({ error, resetError, eventId }) => {
        toast.error("Something went wrong!");

        return (
          <div role="alert">
            <p className="">Something went wrong: </p>
            <pre style={{ color: "red" }}>Error ID: {eventId}</pre>
            <pre style={{ color: "red" }}>{error.message}</pre>
            <button onClick={resetError}>Try again</button>
          </div>
        );
      }}
    >
      {children}
    </SentryErrorBoundary>
  ) : (
    <ReactErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => {
        return (
          <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{(error as Error).message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
          </div>
        );
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};
