import {
  init
} from "@sentry/react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App.tsx";
import "./index.css";

if (import.meta.env.PROD) {
  init({
    environment: import.meta.env.MODE,
    dsn: "https://31d94598888c73b27d2bdb07c3fe448a@o4506672568729600.ingest.sentry.io/4506676089651200",
    attachStacktrace: true,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
