import { Layout } from "@/components/module/Layout";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { Suspense, lazy } from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Loading } from "../components/module/Loading";
import { AcceptInvite } from "./AcceptInvite";
import { NotFound } from "./NotFound";
import { SettingsLayout } from "./Settings";

const LazySettingsProfile = lazy(async () => {
  const { ProfilePage } = await import("./Settings/Profile");
  return {
    default: withAuthenticationRequired(ProfilePage),
  };
});

const LazySettingsAPIKeys = lazy(async () => {
  const { ApiKeysPage } = await import("./Settings/ApiKeys");
  return {
    default: withAuthenticationRequired(ApiKeysPage),
  };
});

const LazySettingsOrganization = lazy(async () => {
  const { OrganizationPage } = await import("./Settings/Organization");
  return {
    default: withAuthenticationRequired(OrganizationPage),
  };
});

const LazySettingsTeam = lazy(async () => {
  const { TeamPage } = await import("./Settings/Team");
  return {
    default: withAuthenticationRequired(TeamPage),
  };
});

const LazySettingsWhatsapp = lazy(async () => {
  const { WhatsappPage } = await import("./Settings/Whatsapp");
  return {
    default: withAuthenticationRequired(WhatsappPage),
  };
});

const LazySettingsChatwoot = lazy(async () => {
  const { ChatwootPage } = await import("./Settings/Chatwoot");
  return {
    default: withAuthenticationRequired(ChatwootPage),
  };
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const appRouter = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    hasErrorBoundary: true,
    children: [
      {
        path: "/accept-invite/:inviteId",
        element: <AcceptInvite />,
      },
      {
        path: "/settings",
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            path: "profile",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsProfile />
              </Suspense>
            ),
          },
          {
            path: "api-keys",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsAPIKeys />
              </Suspense>
            ),
          },
          {
            path: "organization",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsOrganization />
              </Suspense>
            ),
          },
          {
            path: "team",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsTeam />
              </Suspense>
            ),
          },
          {
            path: "whatsapp",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsWhatsapp />
              </Suspense>
            ),
          },
          {
            path: "chatwoot",
            element: (
              <Suspense fallback={<Loading />}>
                <LazySettingsChatwoot />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "/",
        element: <Navigate to="/settings/profile" />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export const AppRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={appRouter} />
    </Suspense>
  );
};
