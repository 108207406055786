import { cn } from "@/lib/utils";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import React, { forwardRef } from "react";

type PopoverContentProps = PopoverPrimitive.PopoverContentProps & {
  className?: string;
  align?: "start" | "center" | "end";
  sideOffset?: number;
};

const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  (
    {
      className,
      align = "center",
      sideOffset = 4,
      ...props
    }: PopoverContentProps,
    ref
  ) => {
    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          align={align}
          sideOffset={sideOffset}
          className={cn(
            "z-[100] w-full overflow-hidden rounded-md border bg-card p-4 text-sm text-card-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
            className
          )}
          ref={ref}
          {...props}
        />
      </PopoverPrimitive.Portal>
    );
  }
);
PopoverContent.displayName = "PopoverContent";

type PopoverProps = {
  popoverRootProps?: PopoverPrimitive.PopoverProps;
  popoverTriggerProps?: PopoverPrimitive.PopoverTriggerProps;
  popoverContentProps?: PopoverPrimitive.PopoverContentProps;
  children: React.ReactNode;
  content: React.ReactNode;
};

export const Popover = (props: PopoverProps) => (
  <PopoverPrimitive.Root {...props.popoverRootProps}>
    <PopoverPrimitive.Trigger asChild {...props.popoverTriggerProps}>
      {props.children}
    </PopoverPrimitive.Trigger>
    <PopoverPrimitive.Portal>
      <PopoverContent {...props.popoverContentProps}>
        {props.content}
      </PopoverContent>
    </PopoverPrimitive.Portal>
  </PopoverPrimitive.Root>
);
