import { Button } from "@/components/base/Button";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-full w-full flex-grow flex-col items-center justify-center gap-5 align-middle">
      <img
        src="/logo/app-icon-light.svg"
        alt="Loading..."
        height="70px"
        width="70px"
      />

      <span className="text-page-title text-tx-secondary">
        404: Seems like you&apos;re lost. Let&apos;s get you back on track.
      </span>

      <Button
        onClick={() => {
          window.history.replaceState({}, "", "/");
          navigate("/");
        }}
      >
        Go Home
      </Button>
    </div>
  );
};
